.addButton {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 40px;
	font: var(--gilroy-Medium-16-24);
	background: var(--color-white);
	border: 2px solid var(--color-light-grey);
	border-top: none;
	cursor: pointer;
}

.addButton img {
	width: 24px;
	margin-right: 8px;
}

.addButton span {
	border-bottom: 1px solid var(--color-dark-grey);
}
