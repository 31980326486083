.wrapper {
	height: 288px;
	margin-top: 8px;
	overflow-y: scroll;
	background-color: var(--color-white);
	border: 1px solid var(--color-grey100);
}

.btnOnly {
	z-index: 999999999999999;
}

.formControl {
	margin: 0px !important;
	width: 100%;
}

.menuItem {
	position: relative;
	height: 48px !important;
	padding: 0 0 0 8px !important;
}

.menuTitle {
	margin-left: -6px;
	font: var(--gilroy-Medium-18-24);
}

.noData {
	text-align: center;
	pointer-events: none;
}

.btnOnly {
	position: absolute;
	top: 0px;
	right: 0px;
	padding: 10px 16px;
	border: none;
	font: var(--gilroy-Medium-14-17);
	background-color: var(--color-black);
	color: var(--color-white);
	animation: btnOnlyAppearence;
	animation-duration: 1s;
	animation-timing-function: ease-in;
}

@keyframes btnOnlyAppearence {
	0% {
		opacity: 0;
	}

	25% {
		opacity: 0;
	}

	50% {
		opacity: 0;
	}

	75% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

.MuiPaper-root.MuiPopover-paper {
	height: 300px;
}

.search {
	padding-left: 13px !important;
	padding-right: 13px !important;
	width: 100%;
}

.search label {
	padding-left: 13px;
}

.help_btn button {
	margin-top: -5px;
	margin-left: 5px;
}

.help_btn svg {
	width: 14px;
}

.search {
	padding-left: 20px !important;
	padding-right: 20px !important;
	width: 100%;
	position: relative;
}

.search::before {
	content: '';
	position: absolute;
	left: 20px;
	top: 70%;
	transform: translateY(-50%);
	width: 24px;
	height: 24px;
	background-image: url('images/loop.svg');
	background-size: cover;
}

.search input {
	padding: 0 0 0 40px !important;
}

.search label {
	padding-left: 60px !important;
}

.buttonResetFilter {
	position: absolute;
	right: 30px;
	top: 27px;
	background-color: transparent;
	font: var(--gilroy-Medium-16-19);
	text-decoration: underline;
	color: var(--color-grey);
	cursor: pointer;
	white-space: nowrap;
	text-transform: lowercase;
	border: none;
	outline: none;
}

.error {
	margin-top: 6px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12-12);
	font-weight: 500;
}
