.tableElement {
	display: grid;
	align-items: center;
	grid-template-columns: 2fr 1fr 180px;
	gap: 15px;
	padding: 12px 0;
}

.tableElement:not(:last-child) {
	border-bottom: 1px solid var(--color-light-grey);
}

.infoWrapper {
	display: flex;
	align-items: center;
	width: 100%;
	max-width: 17vw;
}

.place {
	display: inline-block;
	flex-shrink: 0;
	width: 38px;
	margin-right: 10px;
	padding-left: 8px;
	font: var(--gilroy-Medium-16-24);
	color: var(--color-grey);
}

.cover:visited {
	all: unset;
}

.cover {
	flex-shrink: 0;
	margin-right: 8px;
	width: 40px;
	height: 40px;
	overflow: hidden;
}

.cover img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

.text {
	max-width: 15vw;
	font: var(--gilroy-Medium-16-24);
	line-height: 2;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.title {
	color: var(--color-dark-grey);
}

.cover:hover,
.title:hover {
	opacity: 0.8;
	cursor: pointer;
}

.subtitle {
	font: var(--gilroy-Medium-12-12);
	color: var(--color-grey);
}

.actions {
	display: flex;
	justify-content: space-between;
}

@media (max-width: 1200px) {
	.text {
		max-width: 10vw;
	}
}

@media (min-width: 768px) {
	.tableElement {
		grid-template-columns: 2fr 2fr 180px;
	}
}
