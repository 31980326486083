.container {
	display: flex;
	gap: 16px;
	width: 100%;
}

.sort {
	cursor: grab;
}

.error {
	position: absolute;
	bottom: 0px;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}
